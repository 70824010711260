@import "bootstrap/scss/bootstrap";
@import "./variables";
@import "./utils";
@import "./fonts";
@import "./topbar";
@import "./footer";

* {
  font-family: Manrope, sans-serif;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(128, 128, 128, 10%);
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  background: #DDD;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #CCC;
}

.text-secondary {
  color: $secondary !important;
}

html,
body {
  width: 100%;
  max-width: 100%;
  overflow: auto;
  overflow-x: hidden;
}

html body {
  display: block;
  padding-top: 72px;
}

body {
  padding-right: 0 !important;
}

img {
  max-width: 100%;
}

.container {
  padding-left: 20px;
  padding-right: 20px;

  @include media-breakpoint-up(xl) {
    max-width: 1320px;
  }
}

.form-control {
  &::placeholder {
    color: #6F7785;
    opacity: 1; /* Firefox */
  }
}

.border {
  border-color: #D4DAE54C !important;
}

.nav-tabs {
  border-bottom: 1px solid transparent;
}

.section-features .nav-link:hover:not(.active) {
  border-bottom: 1px solid #DEE2E6 !important;
}

.nav-tabs .nav-link {
  margin-bottom: 0 !important;
  border-bottom: 1px solid #DEE2E6;
}

picture {
  display: inline-block;
  width: 100%;
  max-width: 1280px;
  aspect-ratio: 16 / 8.25;
}

.section-paddings {
  @include media-breakpoint-up(sm) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @include media-breakpoint-up(md) {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 128px;
    padding-bottom: 128px;
  }
}

.section-padding-bottom {
  @include media-breakpoint-up(sm) {
    padding-bottom: 80px;
  }

  @include media-breakpoint-up(md) {
    padding-bottom: 96px;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 128px;
  }
}

.table-th {
  width: 20%;
  min-width: fit-content;
}
