.topbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
  z-index: 100;

  .nav-item {
    padding: 16px 15px;
  }
}

.nav-mobile {
  position: fixed;
  top: 72px;
  right: -360px;
  bottom: 0;
  width: 100%;
  max-width: 360px;
  background-color: #FFF;
  transition: right 0.5s;
  will-change: right;

  > ul > li > a {
    border-left: 2px solid transparent;

    &:hover {
      border-left: 2px solid $primary;
    }
  }

  .nav-mobile__functions-list {
    display: none;

    li {
      padding-left: 19px;
    }
  }

  &__functions-icon {
    position: absolute;
    top: 28%;
    right: -14px;
    display: inline-block;
    transform: rotateZ(313deg);
  }

  &__functions.is-active {
    > a {
      color: $primary !important;
    }

    .nav-mobile__functions-list {
      display: block;
    }

    .nav-mobile__functions-icon {
      transform: rotateZ(135deg);
      top: 17%;
    }
  }

  &.is-active {
    right: 0;
  }
}

.topbar .nav-link:hover,
.topbar li.is-active .nav-link {
  color: $primary !important;
}

.hamburger {
  padding: 13px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  transform: scale(0.8);
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 40px;
  height: 3px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  width: 30px;
  right: 0;
  bottom: -10px;
}

.hamburger.is-active:hover {
  opacity: 0.7;
}

.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #000;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin.is-active .hamburger-inner::after {
  width: 40px;
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hvr-underline-from-left {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0%);
  position: relative;
  overflow: hidden;
}

.hvr-underline-from-left::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 10px;
  right: 100%;
  bottom: 0;
  background: $primary;
  height: 2px;
  transition-property: right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.hvr-underline-from-left:hover::before,
.hvr-underline-from-left:focus::before,
.hvr-underline-from-left:active::before,
.hvr-underline-from-left.is-active::before {
  right: 0;
}

.toast {
  position: fixed;
  top: 85px;
  right: -175px;
  min-width: 360px;
  display: none;
  z-index: 100;
  transform: translateX(-50%);
}
