@import "aos/dist/aos.css";
@import "./common";

.section-title {
  font-weight: 800;

  @include media-breakpoint-up(sm) {
    font-size: 32px;
  }

  @include media-breakpoint-up(md) {
    font-size: 40px;
  }
}

.page-header {
  @include media-breakpoint-up(md) {
    background-image: url("../images/header-bg.jpg");
  }

  position: relative;
  min-height: calc(100vh - 72px);
  background-size: cover;
  background-position-x: 60%;
  background-color: #000;

  &-content {
    max-width: 1040px;
  }

  h1 {
    font-weight: 800;
  }
}

.page-title {
  @include media-breakpoint-up(sm) {
    font-size: 32px;
  }

  @include media-breakpoint-up(md) {
    font-size: 40px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 48px !important;
  }
}

.page-header-description {
  @include media-breakpoint-up(sm) {
    font-size: 14px;
  }

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
}

.page-header-presentation {
  @include media-breakpoint-up(sm) {
    display: block;
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    display: inline-block;
    width: auto;
  }
}

.main-page section {
  min-height: 90vh;
  display: flex;
  align-items: center;
}

.section-purpose {
  &__description {
    max-width: 870px;
    margin: 0 auto;
  }

  &__info {
    min-width: 100px;

    img {
      width: 72px;

      @include media-breakpoint-down(sm) {
        width: 64px;
      }
    }
  }

  &__card {
    height: calc(100% - 16px);
  }
}

.nav-link {
  @include media-breakpoint-down(sm) {
    padding: 0.5rem 0.75rem;
  }
}

.section-features {
  img {
    width: 100%;
  }

  .nav-link {
    &:hover:not(.active) {
      border-color: transparent !important;
      border-bottom-color: #DEE2E6 !important;
    }

    @include media-breakpoint-down(sm) {
      font-size: 14px !important;
      padding: 10px !important;
    }
  }

  &__description {
    @include media-breakpoint-up(xl) {
      width: 65%;
      margin: 0 auto;
    }
  }
}

.section-examples {
  &__image {
    width: 100%;
    height: 166px;
    background-size: 100%;
    background-position: -10%;

    @include media-breakpoint-up(md) {
      background-size: cover;
      background-position: 0 0;
    }

    @include media-breakpoint-up(lg) {
      background-size: 100%;
      background-position: -10%;
    }
  }

  &__example {
    padding-left: 12px !important;
    padding-right: 12px !important;

    &:nth-child(1) .section-examples__image {
      background-image: url("../images/implementation-1.webp");
    }

    &:nth-child(2) .section-examples__image {
      background-image: url("../images/implementation-2.webp");
    }

    &:nth-child(3) .section-examples__image {
      background-image: url("../images/implementation-3.webp");
    }
  }
}

.nav-tabs .nav-item.active {
  color: $primary !important;
}

.nav-item {
  font-weight: 500;
}

.nav-link.active {
  border: none;
  border-bottom: 2px solid $primary !important;
}
