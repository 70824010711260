$primary: #0060E3;
$secondary: #181F2C;
$gray: #525862;

$grid-breakpoints: (
  xs: 0,
  sm: 360px,
  md: 768px,
  lg: 1080px,
  xl: 1440px,
  xxl: 1980px,
);

$container-max-widths: (
  sm: 680px,
  md: 760px,
  lg: 1290px,
  xl: 1310px,
  xxl: 1380px
);
