@font-face {
  font-family: Manrope, sans-serif;
  src: url("../fonts/Manrope-ExtraLight.woff2");
  font-weight: 1;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Manrope;
  src: url("../fonts/Manrope-ExtraLight.woff2");
  font-weight: 200;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Manrope;
  src: url("../fonts/Manrope-Light.woff2");
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Manrope;
  src: url("../fonts/Manrope-Regular.woff2");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Manrope;
  src: url("../fonts/Manrope-Medium.woff2");
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Manrope;
  src: url("../fonts/Manrope-SemiBold.woff2");
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Manrope;
  src: url("../fonts/Manrope-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Manrope;
  src: url("../fonts/Manrope-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
  font-display: fallback;
}
